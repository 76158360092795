import './index.scss'
import '../../assets/scripts/jquery.share.min.js'

$('footer .footer .right ul li .nav-title .iconfont').on('click',function(){
  let $parent = $(this).closest('li')
  if($parent.hasClass('active')){
    $parent.removeClass('active').find('.nav-link').slideUp()
  }else{
    $parent.siblings().removeClass('active').find('.nav-link').slideUp()
    $parent.addClass('active').find('.nav-link').slideDown()
  }
})

$('footer .footer-btm .friend-link .friend-link-title').on('click',function(){
  if($(this).hasClass('active')){
    $(this).removeClass('active')
    $('footer .footer-btm .friend-link .friend-link-cont').slideUp()
  }else{
    $(this).addClass('active')
    $('footer .footer-btm .friend-link .friend-link-cont').slideDown()
  }
})

$(window).resize(function(){
  if($(window).width()>767){
    $('footer .footer .right ul li .nav-link').slideDown()
    $('footer .footer-btm .friend-link .friend-link-cont').slideDown()
  }else{
    $('footer .footer .right ul li .nav-link').slideUp()
    $('footer .footer-btm .friend-link .friend-link-cont').slideUp()
  }
})

$(document).scroll(function () {
  let scroH = $(document).scrollTop(); //滚动高度
  let viewH = $(window).height(); //可见高度
  let contentH = $(document).height(); //内容高度
  let footerTop = $('footer').offset().top

  if(scroH > viewH/2){
    $('footer .aside-icon').addClass('show')
  }else{
    $('footer .aside-icon').removeClass('show')
  }

  if(scroH > footerTop - viewH + 90){
    $('footer .aside-icon').addClass('abs')
  }else{
    $('footer .aside-icon').removeClass('abs')
  }
});

// 返回顶部
$('footer .back-top').on('click',function(){
  $("html, body").animate({
    scrollTop: 0
  }, {
      duration: 500,
      easing: "swing"
  });
  return false;
})

$(document).ready(function() {  
  // 检查是否已经存在缓存  
  if(localStorage.getItem('cookietranmate')){
    if (localStorage.getItem('cookietranmate') === 'rejected') {  
      $('.cookie-consent').removeClass('hide').addClass('show');  
    } else {  
      $('.cookie-consent').addClass('hide').removeClass('show');  
    }  
  }else{
    $('.cookie-consent').removeClass('hide').addClass('show');
  }
  

  // 设置点击事件  
  $('.cookie-consent .reject').on('click', function() {  
    localStorage.setItem('cookietranmate', 'rejected'); // 记录拒绝信息  
    $('.cookie-consent').removeClass('show').addClass('hide');  
  });  

  $('.cookie-consent .accept').on('click', function() {  
    localStorage.setItem('cookietranmate', 'accepted'); // 记录接受信息  
    $('.cookie-consent').removeClass('show').addClass('hide');  
  });  
});