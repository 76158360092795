module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<section class="ny-banner">\r\n  <div class="img" style="background-image: url(' +
((__t = (require('../../assets/images/banner_why.jpg'))) == null ? '' : __t) +
');"></div>\r\n  <div class="banner-t container">\r\n    <h1 class="bold font56 letter100">why transmate</h1>\r\n    <div class="crumbs">\r\n      <a href="#">home</a>\r\n      <span><i class="iconfont icon-jiantou_you"></i></span>\r\n      <a href="#">why transmate</a>\r\n    </div>\r\n  </div>\r\n</section>';

}
return __p
}